import {Box, Button, CircularProgress, Divider, TextField, Typography,} from "@mui/material";
import {useEffect, useState} from "react";
import {fetchViaPost} from "../helpers/fetchViaPost";
import {Calculate, RestartAlt, Save} from "@mui/icons-material";
import {fetchFromApi} from "../helpers/fetchFromApi";
import {LeagueTable} from "./FixtureDetails/LeagueTable";

export const FixtureDetails = ({row, handleRecompile, user}) => {
  async function handleSave() {
    await fetchViaPost(
      "put-team-strengths",
      JSON.stringify([
        {
          ...localTeamStrength,
          total_strength: +localTeamStrength.total_strength,
        },
        {
          ...visitorTeamStrength,
          total_strength: +visitorTeamStrength.total_strength,
        },
      ])
    );
    setLocalTeamStrengthChanged(false);
    setLocalGoalSumExpChanged(false);
    setVisitorTeamStrengthChanged(false);
    setVisitorGoalSumExpChanged(false);
  }

  useEffect(() => {
    async function fetchStrengths() {
      try {
        const teamStrengths = await (
          await fetchViaPost(
            "get-team-strengths",
            JSON.stringify({
              season_id: row.season_id,
              team_ids: [row.localteam_id, row.visitorteam_id],
            })
          )).json();
        let isRegistered = false;
        const strs = [
          "bWFyY3VzLWJiZ0B3ZWIuZGU=",
          "cGF0cmlrLnNjaG1pZHRAeW1haWwuY29t",
          "dGlwc3RlcmNvbXBhbnlAZ214LmRl",
          "dG9ta2x1ZXNlbmVyQHQtb25saW5lLmRl",
          "ZGVubmlzb21lbHRzY2hlbmtvQHdlYi5kZQ==",
          "emFzdGVyNjBAb25saW5lLmRl",
          "Zi5zcGllZ2VsQGhvdG1haWwuZGU=",
          "bWhlaWRidWVjaGVsQG1haWwuZGU=",
          "aGVpbWFubi5oQGFvbC5jb20=",
          "c3RlZmZlbndlaWNrLjFAZ214LmRl",
          "dGFzY2hlbGVlci50bWxAZ29vZ2xlbWFpbC5jb20=",
          "eWFubmljZ295bkBnbWFpbC5jb20=",
          "amVucy5zY2hlbmtlQGdvb2dsZW1haWwuY29t",
          "a3Vuei5zdDNmYW5AZ21haWwuY29t",
          "eWFubmljZ295bjU3QGdtYWlsLmNvbQ==",
          "bWlvczc4QGdteC5kZQ==",
          "dW5kZXJncm91bmRzYWFyQGFvbC5jb20=",
          "dGhvbWFzLmJ1c2xAZ21haWwuY29t",
          "dHJhaW5lcjhAZ214LmRl",
          "YnNiYXNzYm9vc3RlZEBnbWFpbC5jb20=",
          "cGF1bGVAcGF1bGUtd2V0dGVuLmRl",
          "WWFzaW4tU2NocmVpYmVyQHdlYi5kZQ==",
          "YmVuYmFsazIuMEBnbWFpbC5jb20=",
          "bWFyaXVzLm1laXNidXJnQGdtYWlsLmNvbQ==",
          "cXVlbnRpbi5oZXJyZW5icnVlY2tAZ214Lm5ldA==",
          "ZGF2aWQuYm90c2NoZW5AZ21haWwuY29t",
          "anVhbm1hc3NhbmV0QGdteC5kZQ==",
          "cm9iZGVtYXJjbzk1QGxpYmVyby5pdA==",
          "cy5tb2VsbGVyNzlAZ21haWwuY29t",
          "Y2hyaXN0aWFuaGlsY2hlckBmcmVlbmV0LmRl",
          "bXIubW9saUB3ZWIuZGU=",
          "bGllc2tlLmJlbml0ekB0LW9ubGluZS5kZQ==",
          "ZGVtb2huQG91dGxvb2suZGU="
        ];
        if (typeof user !== "undefined" && strs.includes(btoa(user.id))) {
          isRegistered = true;
        }
        setIsRegistered(isRegistered);

        var originalLocalTeamStrength = teamStrengths[row.localteam_id];
        var originalVisitorTeamStrength = teamStrengths[row.visitorteam_id];

        setOriginalVisitorTeamStrength(originalVisitorTeamStrength);
        setOriginalLocalTeamStrength(originalLocalTeamStrength);


        var localGoalSumExp = (originalLocalTeamStrength.against_total + originalLocalTeamStrength.for_total)?.toFixed(3) ?? "-";
        setOriginalLocalGoalSumExp({goal_sum_exp: Number(localGoalSumExp)})
        setLocalGoalSumExp({
            goal_sum_exp: Number(localGoalSumExp)
          }
        );

        var visitorGoalSumExp = (originalVisitorTeamStrength.against_total + originalVisitorTeamStrength.for_total)?.toFixed(3) ?? "-";
        setOriginalVisitorGoalSumExp({goal_sum_exp: Number(visitorGoalSumExp)})
        setVisitorGoalSumExp({
            goal_sum_exp: Number(visitorGoalSumExp)
          }
        );

        setLocalTeamStrength({
          ...teamStrengths[row.localteam_id],
        });
        setVisitorTeamStrength({
          ...teamStrengths[row.visitorteam_id],
        });
      } catch (e) {
        //console.error(e);
      }
    }

    async function fetchStandings() {
      try {
        const response = await (
          await fetchFromApi(`fetch-data/standings/season/${row.season_id}`)
        ).json();
        var standings
        let data = response?.data;
        var t1, t2
        var found1 = data?.find(function (it) {
          let f = it.standings.data.find((e) => e.team_id === row.localteam_id);
          if (typeof f !== "undefined") {
            t1 = f
            return f;
          }
        });
        let found2 = data?.find(function (it) {
          let f = it.standings.data.find((e) => e.team_id === row.visitorteam_id);
          if (typeof f !== "undefined") {
            t2 = f;
            return f;
          }
        });
        standings = [t1, t2]

        const stats = standings.reduce((prev, e) => {
          if (row.localteam_id === e.team_id) {
            setEarnedStrengthHome(e?.overall?.goals_scored / e?.overall?.goals_against);
            setEarnedGoalSumHome((e?.overall?.goals_scored + e?.overall?.goals_against) / e?.overall?.games_played)
          }
          if (row.visitorteam_id === e.team_id) {
            setEarnedStrengthAway(e?.overall?.goals_scored / e?.overall?.goals_against)
            setEarnedGoalSumAway((e?.overall?.goals_scored + e?.overall?.goals_against) / e?.overall?.games_played)
          }
          const overallDiff =
            e?.overall?.goals_scored - e?.overall?.goals_against;
          const homeDiff = e?.home?.goals_scored - e?.home?.goals_against;
          const awayDiff = e?.away?.goals_scored - e?.away?.goals_against;
          return {
            ...prev,
            [e.team_id]: {
              league: {
                position: e?.position,
                games: e?.overall?.games_played,
                won: e?.overall?.won, //S
                draw: e?.overall?.draw, //U
                lost: e?.overall?.lost, //N
                goals: `${e?.overall?.goals_scored}:${e?.overall?.goals_against}`,
                diff: overallDiff > 0 ? `+${overallDiff}` : overallDiff,
                points: e?.overall?.points,
              },
              home: {
                position: e?.home?.position || "-",
                games: e?.home?.games_played,
                won: e?.home?.won, //S
                draw: e?.home?.draw, //U
                lost: e?.home?.lost, //N
                goals: `${e?.home?.goals_scored}:${e?.home?.goals_against}`,
                diff: homeDiff > 0 ? `+${homeDiff}` : homeDiff,
                points: e?.home?.points,
              },
              away: {
                position: e?.away?.position || "-",
                games: e?.away?.games_played,
                won: e?.away?.won, //S
                draw: e?.away?.draw, //U
                lost: e?.away?.lost, //N
                goals: `${e?.away?.goals_scored}:${e?.away?.goals_against}`,
                diff: awayDiff > 0 ? `+${awayDiff}` : awayDiff,
                points: e?.away?.points,
              },
            },
          };
        }, {});

        setStats(stats);
      } catch (e) {
        //console.error(e);
      }
    }

    if (row.id) {
      fetchStrengths();
      fetchStandings();
    }
  }, [row.id]);
  const [localTeamStrength, setLocalTeamStrength] = useState();
  const [localTeamStrengthChanged, setLocalTeamStrengthChanged] = useState(false);
  const [localGoalSumExp, setLocalGoalSumExp] = useState();
  const [localGoalSumExpChanged, setLocalGoalSumExpChanged] = useState(false);
  const [earnedStrengthHome, setEarnedStrengthHome] = useState(null);
  const [earnedGoalSumHome, setEarnedGoalSumHome] = useState(null);
  const [earnedStrengthAway, setEarnedStrengthAway] = useState(null);
  const [earnedGoalSumAway, setEarnedGoalSumAway] = useState(null);
  const [stats, setStats] = useState([]);
  const [visitorTeamStrength, setVisitorTeamStrength] = useState();
  const [visitorTeamStrengthChanged, setVisitorTeamStrengthChanged] = useState(false);
  const [visitorGoalSumExp, setVisitorGoalSumExp] = useState();
  const [visitorGoalSumExpChanged, setVisitorGoalSumExpChanged] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);

  const [originalLocalTeamStrength, setOriginalLocalTeamStrength] = useState();
  const [originalLocalGoalSumExp, setOriginalLocalGoalSumExp] = useState();
  const [originalVisitorTeamStrength, setOriginalVisitorTeamStrength] = useState();
  const [originalVisitorGoalSumExp, setOriginalVisitorGoalSumExp] = useState();
  if (!localTeamStrength) {
    return (
      <Box sx={{background: "white"}}>
        <Box
          sx={{
            p: "5em",
            display: "flex",
            justifyContent: "center",
            background: "rgb(255,181,99,0.2)",
          }}
        >
          <CircularProgress/>
        </Box>
      </Box>
    );
  }

  const handleLocalGoalSumExp = ({
                                   target: {value: goalSumExpNew},
                                 }) =>
    setLocalGoalSumExp((s) => {
      var againstTotalNew = goalSumExpNew / (originalLocalTeamStrength.total_strength + 1);
      var forTotalNew = originalLocalTeamStrength.total_strength * againstTotalNew;
      var againstAwayNew = againstTotalNew * (2 - originalLocalTeamStrength.against_homefactor);
      var forAwayNew = forTotalNew * (2 - originalLocalTeamStrength.for_homefactor);
      var againstHomeNew = againstTotalNew * originalLocalTeamStrength.against_homefactor;
      var forHomeNew = forTotalNew * originalLocalTeamStrength.for_homefactor;

      const newLocalGoalSumExp = {
        ...s,
        goal_sum_exp: Number(goalSumExpNew)
      }
      setLocalTeamStrengthChanged(true);
      localTeamStrength["against_away"] = againstAwayNew;
      localTeamStrength["against_home"] = againstHomeNew;
      localTeamStrength["against_total"] = againstTotalNew;
      localTeamStrength["for_away"] = forAwayNew;
      localTeamStrength["for_home"] = forHomeNew;
      localTeamStrength["for_total"] = forTotalNew;

      setLocalTeamStrength(localTeamStrength);
      return newLocalGoalSumExp;
    })

  const handleVisitorGoalSumExp = ({
                                     target: {value: goalSumExpNew},
                                   }) =>
    setVisitorGoalSumExp((s) => {
      var againstTotalNew = goalSumExpNew / (originalVisitorTeamStrength.total_strength + 1);
      var forTotalNew = originalVisitorTeamStrength.total_strength * againstTotalNew;
      var againstAwayNew = againstTotalNew * (2 - originalVisitorTeamStrength.against_homefactor);
      var forAwayNew = forTotalNew * (2 - originalVisitorTeamStrength.for_homefactor);
      var againstHomeNew = againstTotalNew * originalVisitorTeamStrength.against_homefactor;
      var forHomeNew = forTotalNew * originalVisitorTeamStrength.for_homefactor;

      const newVisitorGoalSumExp = {
        ...s,
        goal_sum_exp: Number(goalSumExpNew)
      }
      setVisitorTeamStrengthChanged(true);
      visitorTeamStrength["against_away"] = againstAwayNew;
      visitorTeamStrength["against_home"] = againstHomeNew;
      visitorTeamStrength["against_total"] = againstTotalNew;
      visitorTeamStrength["for_away"] = forAwayNew;
      visitorTeamStrength["for_home"] = forHomeNew;
      visitorTeamStrength["for_total"] = forTotalNew;

      setVisitorTeamStrength(visitorTeamStrength);
      return newVisitorGoalSumExp;
    })

  const handleLocalTeamStrengthChange = ({
                                           target: {value: total_strength},
                                         }) =>
    setLocalTeamStrength((s) => {
      var total_goals = (+originalLocalTeamStrength.for_total + originalLocalTeamStrength.against_total);
      var against_total = total_goals / (Math.abs(+total_strength + 1));
      var for_total = total_goals - against_total;

      const newLocalTeamStrength = {
        ...s,
        total_strength: (+total_strength).toFixed(3),
        for_home: for_total * originalLocalTeamStrength.for_homefactor,
        against_home: against_total * originalLocalTeamStrength.against_homefactor,
        for_away: for_total * (2 - originalLocalTeamStrength.for_homefactor),
        against_away: against_total * (2 - originalLocalTeamStrength.against_homefactor),
        against_total: against_total,
        for_total: for_total
      };
      setLocalGoalSumExpChanged(true);
      return newLocalTeamStrength;
    });
  const handleVisitorTeamStrengthChange = ({
                                             target: {value: total_strength},
                                           }) =>
    setVisitorTeamStrength((s) => {
      var total_goals = (+originalVisitorTeamStrength.for_total + originalVisitorTeamStrength.against_total);
      var against_total = total_goals / (Math.abs(+total_strength + 1));
      var for_total = total_goals - against_total;

      const newVisitorTeamStrength = {
        ...s,
        total_strength: (+total_strength).toFixed(3),
        for_home: for_total * originalVisitorTeamStrength.for_homefactor,
        against_home: against_total * originalVisitorTeamStrength.against_homefactor,
        for_away: for_total * (2 - originalVisitorTeamStrength.for_homefactor),
        against_away: against_total * (2 - originalVisitorTeamStrength.against_homefactor),
        against_total: against_total,
        for_total: for_total
      };
      setVisitorGoalSumExpChanged(true);
      return newVisitorTeamStrength;
    });

  return (
    <Box style={{background: "white"}}>
      <Box
        sx={{p: 2}}
        style={{
          background: "rgb(255,181,99,0.2)",
          flexDirection: "column",
          display: "flex",
          gap: 5,
        }}
      >
        <Box style={{display: "flex"}}>
          <Box style={{flex: 1}}>
            <Typography
              component="h1"
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
                justifyContent: "center",
              }}
            >
              <img
                src={row.localTeam.data.logo_path}
                style={{height: "1.5em"}}
                alt=""
              />
              {row.localTeam.custom_name}
            </Typography>
            <Box style={{display: "block", marginTop: "0.5em"}}>
              <Box
                style={{flex: 1, display: "flex", justifyContent: "space-around"}}
              >
                <TextField
                  onChange={handleLocalTeamStrengthChange}
                  style={{margin: '5px', marginTop: '9px'}}
                  id="outlined-number"
                  label="Stored strength"
                  title="This is the stored strength of the team. Compare it with the Earned strength to check your settings. Adjust, if necessary or wished. Store the settings, if you want to store them. Recompile after your adjustments, with or without storing. If not stored, the changes will take effect just on that one fixture."
                  disabled={localTeamStrengthChanged}
                  type="number"
                  value={(+localTeamStrength.total_strength).toFixed(3)}
                  min="0"
                  inputProps={{step: "0.005"}}
                />
                {row.goalexp?.home?.toFixed(3) ? (
                  <TextField
                    onChange={handleLocalGoalSumExp}
                    style={{margin: '5px', marginTop: '9px'}}
                    id="outlined-number"
                    label="Stored goal sum (exp)"
                    title="This is the stored goal sum expected for this team. Compare it with the earned goal sum. Adjust, if you are unhappy with your settings or if you see huge differences. Store the settings if you like them. Recompile after adjustment. If settings are not stored, the changes will just take effect for that one fixture."
                    type="number"
                    disabled={localGoalSumExpChanged}
                    value={(+localGoalSumExp.goal_sum_exp)} //{row.goalexp?.home?.toFixed(3) ?? "-"}
                    min="0"
                    inputProps={{step: "0.005"}}
                  />
                ) : (
                  <TextField
                    onChange={() => {
                    }}
                    style={{margin: '5px', marginTop: '9px'}}
                    label="Stored goal sum (exp)"
                    title="This is the stored goal sum expected for this team. Compare it with the earned goal sum. Adjust, if you are unhappy with your settings or if you see huge differences. Store the settings if you like them. Recompile after adjustment. If settings are not stored, the changes will just take effect for that one fixture."
                    disabled
                    value="Compile the match first"
                  />
                )}
              </Box>
            </Box>

            {isRegistered && <Box style={{display: "flex", marginTop: "0.5em"}}>
              <Box
                style={{flex: 1, display: "flex", justifyContent: "space-around"}}
              >
                {(earnedStrengthHome !== null && !isNaN(earnedStrengthHome)) ? <TextField
                  id="outlined-number"
                  label="Earned strength"
                  style={{margin: '5px'}}
                  disabled
                  type="number"
                  value={earnedStrengthHome.toFixed(3)}
                  min="0"
                /> : ""}
                {row.goalexp?.home?.toFixed(3) ? (
                  (earnedGoalSumHome !== null && !isNaN(earnedGoalSumHome)) ? <TextField
                    id="outlined-number"
                    label="Earned goal sum"
                    style={{margin: '5px'}}
                    type="number"
                    disabled
                    value={earnedGoalSumHome.toFixed(3)} //{row.goalexp?.home?.toFixed(3) ?? "-"}
                    min="0"
                  /> : ""
                ) : (
                  <TextField
                    onChange={() => {
                    }}
                    label="Goal sum exp home team"
                    style={{margin: '5px'}}
                    disabled
                    value="Compile the match first"
                  />
                )}
              </Box>
            </Box>}
            <Box
              style={{flex: 1, display: "flex", justifyContent: "space-around", marginTop: "0.5em"}}
            >
              {row.goalexp?.home?.toFixed(3) ? (
                <TextField
                  onChange={() => {
                  }}
                  id="outlined-number"
                  label="Home goal exp"
                  title="The goal expectancies for this one fixture for the home side, as a result of all the parameters used and set. Home goals expected are as well the goals the away side expects to concede. Basis for the true price calculation for all other markets and prices."
                  disabled
                  type="number"
                  value={row.goalexp?.home?.toFixed(3) ?? "-"}
                  min="0"
                  inputProps={{step: "0.005"}}
                />
              ) : (
                <TextField
                  onChange={() => {
                  }}
                  title="The goal expectancies for this one fixture for the home side, as a result of all the parameters used and set. Home goals expected are as well the goals the away side expects to concede. Basis for the true price calculation for all other markets and prices."
                  label="Home goal exp"
                  disabled
                  value="Compile the match first"
                />
              )}
            </Box>
          </Box>
          <Box style={{flex: 1}}>
            <Typography
              component="h1"
              variant="h5"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5em",
                justifyContent: "center",
              }}
            >
              <img
                src={row.visitorTeam.data.logo_path}
                style={{height: "1.5em"}}
                alt=""
              />
              {row.visitorTeam.custom_name}
            </Typography>
            <Box style={{display: "flex", marginTop: "0.5em"}}>
              <Box
                style={{flex: 1, display: "flex", justifyContent: "space-around"}}
              >
                <TextField
                  onChange={handleVisitorTeamStrengthChange}
                  id="outlined-number"
                  label="Stored strength"
                  style={{margin: '5px', marginTop: '9px'}}
                  title="This is the stored strength of the team. Compare it with the Earned strength to check your settings. Adjust, if necessary or wished. Store the settings, if you want to store them. Recompile after your adjustments, with or without storing. If not stored, the changes will take effect just on that one fixture."
                  disabled={visitorTeamStrengthChanged}
                  type="number"
                  value={(+visitorTeamStrength.total_strength).toFixed(3)}
                  min="0"
                  inputProps={{step: "0.005"}}
                />
                {row.goalexp?.away?.toFixed(3) ? (
                  <TextField
                    onChange={handleVisitorGoalSumExp}
                    id="outlined-number"
                    label="Stored goal sum (exp)"
                    style={{margin: '5px', marginTop: '9px'}}
                    title="This is the stored goal sum expected for this team. Compare it with the earned goal sum. Adjust, if you are unhappy with your settings or if you see huge differences. Store the settings if you like them. Recompile after adjustment. If settings are not stored, the changes will just take effect for that one fixture."
                    type="number"
                    value={(+visitorGoalSumExp.goal_sum_exp)} //{row.goalexp?.home?.toFixed(3) ?? "-"}
                    disabled={visitorGoalSumExpChanged}
                    min="0"
                    inputProps={{step: "0.005"}}
                  />
                ) : (
                  <TextField
                    onChange={() => {
                    }}
                    label="Stored goal sum (exp)"
                    style={{margin: '5px'}}
                    title="This is the stored goal sum expected for this team. Compare it with the earned goal sum. Adjust, if you are unhappy with your settings or if you see huge differences. Store the settings if you like them. Recompile after adjustment. If settings are not stored, the changes will just take effect for that one fixture."
                    disabled
                    value="Compile the match first"
                  />
                )}
              </Box>
            </Box>
            {isRegistered && <Box style={{display: "flex", marginTop: "0.5em"}}>
              <Box
                style={{flex: 1, display: "flex", justifyContent: "space-around"}}
              >
                {(earnedStrengthAway !== null && !isNaN(earnedStrengthAway)) ? <TextField
                  id="outlined-number"
                  label="Earned strength"
                  style={{margin: '5px'}}
                  disabled
                  type="number"
                  value={earnedStrengthAway.toFixed(3)}
                /> : ""}
                {row.goalexp?.away?.toFixed(3) ? (
                    (earnedGoalSumAway !== null)?<TextField
                    id="outlined-number"
                    style={{margin: '5px'}}
                    label="Earned goal sum"
                    type="number"
                    value={earnedGoalSumAway.toFixed(3)} //{row.goalexp?.home?.toFixed(3) ?? "-"}
                    disabled
                  />:""
                ) : (
                  <TextField
                    onChange={() => {
                    }}
                    label="Goal sum exp away team"
                    style={{margin: '5px'}}
                    disabled
                    value="Compile the match first"
                  />
                )}
              </Box>
            </Box>}
            <Box
              style={{flex: 1, display: "flex", justifyContent: "space-around", marginTop: "0.5em"}}
            >
              {row.goalexp?.home?.toFixed(3) ? (
                <TextField
                  onChange={() => {
                  }}
                  id="outlined-number"
                  label="Away goal exp"
                  title="The goal expectancies for this one fixture for the away side, as a result of all the parameters used and set. Away goals expected are as well the goals the home side expects to concede. Basis for the true price calculation for all other markets and prices."
                  disabled
                  type="number"
                  value={row.goalexp?.away?.toFixed(3) ?? "-"}
                  min="0"
                  inputProps={{step: "0.005"}}
                />
              ) : (
                <TextField
                  onChange={() => {
                  }}
                  title="The goal expectancies for this one fixture for the away side, as a result of all the parameters used and set. Away goals expected are as well the goals the home side expects to concede. Basis for the true price calculation for all other markets and prices."
                  label="Away goal exp"
                  disabled
                  value="Compile the match first"
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box style={{display: "flex"}}>
          <Box style={{flex: 1}}>
            <LeagueTable stats={stats} localteamId={row.localteam_id} localTeam={row.localTeam}
                         visitorteamId={row.visitorteam_id} visitorTeam={row.visitorTeam}/>
          </Box>
          <Box style={{flex: 1}}>
            {/*<LastGames localTeam={row.localTeam} visitorTeam={row.visitorTeam}/>*/}
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "0.5em",
          }}
        >
          <Box style={{display: "flex", gap: 5}}>
            <Button
              title="Save the current settings, if you are happy with them. All changes will be stored, for both sides, in case you adjusted some values."
              variant="contained" onClick={() => handleSave()}>
              <Save/>
              <Box style={{marginLeft: ".5em"}}>Save</Box>
            </Button>
            <Button
              title="Reset your changes if you do not like them."
              variant="contained"
              onClick={() => {
                setLocalTeamStrengthChanged(false);
                setLocalGoalSumExpChanged(false);
                setLocalGoalSumExp(originalLocalGoalSumExp);
                setLocalTeamStrength(originalLocalTeamStrength);
                setVisitorTeamStrengthChanged(false);
                setVisitorGoalSumExpChanged(false);
                setVisitorGoalSumExp(originalVisitorGoalSumExp);
                setVisitorTeamStrength(originalVisitorTeamStrength);
              }}
            >
              <RestartAlt/>
              <Box style={{marginLeft: ".5em"}}>Reset</Box>
            </Button>
            <Button
              title="Recompile the prices for this fixture. Any changes will be applied, even if you did not store your adjustments. In case you did not store them, the changes will have their effect just for this one fixture."
              variant="contained"
              onClick={() =>
                handleRecompile(
                  row.id,
                  row.season_id,
                  localTeamStrength,
                  visitorTeamStrength
                )
              }
            >
              <Calculate/>
              <Box style={{marginLeft: ".5em"}}>
                {typeof row.homewin === "undefined" ? "Recompile" : "Compile"}
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider style={{height: "2em"}}/>
    </Box>
  );
};
